@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

@import "common/global";
@import "components/forms";
@import "components/grid";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/pages";