#cookie-bar {
  background: $brand-primary;
  height: auto;
  line-height: 24px;
  color: #eee;
  text-align: center;
  padding: 10px 0;

  p {
    margin: 0;
    padding: 0
  }

  a {
    color: #fff;
    display: inline-block;
    border-radius: 3px;
    text-decoration: none;
    padding: 0 6px;
    margin-left: 8px
  }

  .cb-disable, .cb-enable {
    background: $brand-info;

    &:hover {
      background: darken($brand-info, 10%);
    }
  }

  .cb-policy {
    background: #fff;
    color: #000;

    &:hover {
      background: #fff;
      color: #000;
    }

  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  &.fixed, &.bottom {
    bottom: 0;
    top: auto;
  }

}

.center-block {
  width: 100%;
  margin: 0 auto;
}

.object-fit-cover {
  object-fit: cover;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (min-width: 544px) {}

@media (min-width: 768px) {
  .center-block {
    width: 60%;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}