.content-info {
  .container {
    .logo-footer {
      background: url("../images/logo-dark.svg") no-repeat;
      background-size: contain;
      width: 124px;
      height: 46px;
    }

    .social {
      h6 {
        font-size: 0.85rem;
      }

      ul {
        li {
          a {
            background-size: contain;
            width: 37px;
            height: 37px;
            display: inline-block;
          }

          #facebook {
            background: url("../images/facebook.svg") no-repeat;
          }

          #instagram {
            background: url("../images/instagram.svg") no-repeat;
          }
        }
      }
    }

    .credits {
      border-top: 1px solid $brand-info;
      text-align: initial;
    }
  }
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}


/*
.content-info{
  padding: 60px 0;
  float: left;
  width: 100%;
  color: #c8c8c8;
  .container{
    border-top: 1px solid #424242;
    padding-top: 60px;
    .row{
      margin-bottom: 40px;
      div{
        margin-bottom: 40px;
        h4{
          text-transform: uppercase;
          font-size: 12px;
          margin-top: 0;
          color: #fff;
        }
        .logo-footer{
          background: url(../images/logo.svg) no-repeat;
          width: 190px;
          height: 55px;
          margin: 0 auto;
        }
        .site{
          background: transparent;
          border: 3px solid #f5873e;
          border-radius: 30px;
          color: #f7f8f9;
          letter-spacing: 1px;
          display: inline-block;
          text-align: center;
          padding: 16px 15px;
        }
        .social{
          li{
            margin: 0 10px;
            display: inline-block;
            a{
              display: block;
              float: left;
              width: 36px;
              height: 37px;
            }
            &:first-child{
              display: inline-block;
              background: url(../images/facebook.png) no-repeat;
              background-size: 36px 37px;

            }
            &:nth-child(2){
              display: inline-block;
              background: url(../images/instagram.png) no-repeat;
              background-size: 36px 37px;

            }
          }
        }
      }
    }
  }


  @media (min-width: 544px) {}
  @media (min-width: 768px) {

  }
  @media (min-width: 992px) {

  }
  @media (min-width: 1200px) {
    .container{
      .row{
        div{
          .logo-footer{
            width: 240px;
            height: 69px;
          }
        }
      }
    }
  }

}*/
