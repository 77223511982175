// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary: #0E3543;
$brand-info: #CEB797;
$brand-danger: #DA4453;

//Background
$body-bg: #fff;

//font
$font-family-sans-serif: 'Open Sans', sans-serif;

//buttons
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;

$btn-danger-bg: #DA4453;

//forms
$input-bg: #fafafa;
$input-border-color:#575656;
$input-color-placeholder:#767676;
$input-padding-x:1.62rem;
$input-padding-y:1rem;