// Search form
.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

input {
  font-size: 16px;
  font-family: Open Sans, sans-serif;
  background: #fff !important;
}

.custom-select {
  width: 100%;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: $brand-primary;

}

label.error {
  color: $brand-danger;
}

.file-area {
  width: 100%;
  position: relative;
  background: #fafafa;
  border: 2px dotted #e6e6e6;
  /*border-radius: 4px;*/

  label.error {
    padding: 15px;
  }

  &.selected {
    input[type="file"]:valid + .file-dummy {
      border-color: rgba(0, 255, 0, .4);
      background-color: #0a8f7c;
    }

    input[file="file"]:valid + .file-dummy .success {
      display: inline-block;
    }

    input[file="file"]:valid + .file-dummy .default {
      display: none;
    }

    .file-dummy {
      color: #fff;

      .success {
        display: block;
      }

      .default {
        display: none;
      }
    }

  }

  input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    outline: none;
  }

  input[type="file"]:active {
    outline: none;
  }

  input[type="file"]:focus {
    outline: none;
  }

  input[type="file"]:focus + .file-dummy {
    outline: 2px solid hsla(0, 0%, 100%, .5);
    outline: 5px auto -webkit-focus-ring-color
  }

  .file-dummy {
    width: 100%;
    padding: 30px;
    background: hsla(0, 0%, 100%, .2);
    border: 2px dashed hsla(0, 0%, 100%, .2);
    text-align: center;
    -webkit-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
    color: #767676;
    font-family: Open Sans, sans-serif;
    font-size: 16px;

    .success {
      display: none;
    }
  }

  &:hover {
    .file-dummy {
      background: hsla(0, 0%, 100%, .1);
    }
  }
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}