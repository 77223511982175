.banner{
  position: relative;
  color: #fff;
  overflow: hidden;

  .black-opacity {
    background: black;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: .5;
  }


  .container {
    z-index: 9;
    position: relative;
  }

  .brand{
    background: url(../images/logo.svg) no-repeat;
    background-size: contain;
    width: 162px;
    height: 60px;
    display: inline-block;
  }

  video {
    position: absolute;
  }

  @media (min-width: 544px) {}
  @media (min-width: 768px) {}
  @media (min-width: 992px) {}
  @media (min-width: 1200px) {}

}
