body.modal-open {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  padding-right: 0 !important;
  bottom: 0;
}

//Front page
.main {
  background: #faf8f8;

  .section-title {
    span {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 50%;
        left: 25%;
        border: 2px solid #ceb797;
        bottom: 0;
      }
    }
  }

  .partners {
    .row {
      div {
        img {
          max-width: 250px;
          height: auto;
          max-height: 162px;
        }
      }
    }
  }

  .why-sell {
    .row {
      > div {
        min-height: 180px;

        span.green-check {
          background: url("../images/green-check.png");
          width: 30px;
          height: 23px;
          background-size: 30px 23px;
        }

        span.border-secondary {
          width: 25%;
          height: 5px;
          background: #ceb797;
          margin: 0 auto;
        }
      }
    }

    a {
      max-width: 200px;
    }
  }

  .video-message {
    .container {
      /*video, iframe {
        width: 100%;
        max-width: 700px;
        border: 6px solid $brand-info;
      }*/

      .embed-responsive {
        max-width: 700px;
        border: 6px solid $brand-info;
      }

      a.btn {
        max-width: 400px;
        width: 100%;
      }
    }
  }

  .guide {
    border-top: 1px solid $brand-info;
    border-bottom: 1px solid $brand-info;

    .container {
      .row {
        div {
          span {
            position: relative;

            &:after {
              content: "";
              position: absolute;
              height: 1px;
              width: 25%;
              left: 37.5%;
              border: 2px solid #ceb797;
              bottom: 0;
            }
          }

          p {
            color: $brand-primary;
            font-size: 0.75rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  .module {
    background: url("../images/sheet.png") center no-repeat;
    background-size: cover;
    border-bottom: 1px solid $brand-info;

    .container {
      h4 {
        color: $brand-primary;
        line-height: 2.1rem;
      }

      #form {
      }
    }
  }

  .reviews {
    border-bottom: 1px solid $brand-info;
    border-top: 1px solid $brand-info;
  }

  &.thanks-page {
    background: url("../images/sheet.png");
    background-size: cover;
    border-top: 1px solid $brand-info;
    border-bottom: 1px solid $brand-info;
  }
}

.modal {
  .modal-header {

    .close {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}

@media (min-width: 576px) {
  .main {
    .guide {
      .container {
        .row {
          div {
            background: url("../images/arrow-guide.svg") no-repeat;
            background-size: 12px 23px;
            background-position: center right;

            &:last-child {
              background: none;
            }
          }
        }
      }
    }

    .module {
      .container {
        #form {
          max-width: 80%;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .main {
    .module {
      .container {
        #form {
          max-width: 70%;
        }
      }
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
